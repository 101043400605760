import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';

import {useLocation, useParams} from "react-router";
import {
    createIssue,
    getCategoryList, getIssueList,
    getIssuTypes,
    getQuicksightData,
    getQuicksightDataDetails, issueAssociation, updateIssue
} from "../../services/API";
import CircularProgress from "@mui/material/CircularProgress";
import {
    AppBar, Checkbox,
    Dialog, DialogActions, DialogContent,
    Divider,
    FilledInput,
    Grid,
    InputAdornment,
    InputBase,
    ListItemText, OutlinedInput,
    Paper, Toolbar,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DownloadIcon from '@mui/icons-material/Download';
import HomeMiniIcon from '@mui/icons-material/HomeMini';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import AddIcon from '@mui/icons-material/Add';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Constants} from "./Constants";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Box from "@mui/material/Box";

//import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import {DataGrid,GridToolbar} from "@mui/x-data-grid";
import CustomPaper from "./CustomPaper";
import EditIcon from '@mui/icons-material/Edit';
import CustomSuccessMessage from "./CustomSuccessMessage";
import {useNavigate} from "react-router-dom";


let HEURISTIC = "HEURISTIC"
let STITCHING_VIEW = "STITCHING_VIEW"
let VOR_LOG = "VOR_LOG"
let ALL_ERRORS = "ALL_ERRORS"
let CORRECTED_ERR = "CORRECTED_ERR"
let RESPONSE = "RESPONSE"
let ST_PACKAGE = "ST_PACKAGE"
let ST_BRAND = "ST_BRAND"
let ST_CORRECTIONS = "ST_CORRECTIONS"
let PICKLIST = "PICKLIST"

let TEXT_FILE_TYPE = "Text"
let HTML_FILE_TYPE = ".html"

let JSON_FILE_TYPE = ".json"
let LOG_FILE_TYPE = ".log"
let downloadFileType = "";


let logData = "";
let textData = ""
let fileName = "";

let browseFile = null;
let objCategory = null;
let objType = null;
let issueTypeId = null;
let issueCategoryId = null;
let issuesArray = [];
let categoryArray = [];
let issueId = null;
let operation = ""
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


//let isCreate = false;
const QuickSightDataDetails = () => {
    const location = useLocation();
    const [progress,setProgress] = useState(false);
    const [data,setData] = useState("");
    const [fileType,setFileType] = useState("")

    const[dc,setDC] = useState("")
    const[wrapper,setWrapper] = useState("")
    const[picklist,setPickList] = useState("")
    const[tDate,setTDate] = useState("")
    const[sysErr,setSysErr] = useState("")
    const[expWeight,setExpWeight] = useState("")
    const[actWeight,setActWeight] = useState("")
    const[delta,setDelta] = useState("")
    const[threshold,setThreshold] = useState("")
    const[expCC,setExpCC] = useState("")
    const[actCC,setActCC] = useState("")

    //const [isCopy,setCopy] = useState(false)

    const[minimize,setMinimize] = useState(false)

    const [isCreate,setCreate] = useState(true)
    const [isCopy,setCopy] = useState(false)
    const [open, setOpen] = useState(false);//API errors


    const [windowWidth,setWindowWidth] = useState(1000)
    const [attchFileName,setAttachFileName] = useState("")

    const [categories,setCategories] = useState([])
    const [issueTypes,setIssueTypes] = useState([])
    const [descriptions,setDescriptions] = useState([])
    const [statuses,setStatuses] = useState(['Open','in-progress','closed'])

    const [issueCategories,setIssueCategories] = useState([])
    const [typeList,setTypeList] = useState([])
    const [issueList,setIssueList] = useState([])

    const [catValue,setCatValue] = useState("")
    const [issueName,setIssueName] = useState("");
    const [issueType,setIssueType] = useState("")
    const [jiraTicket,setJiraTicket] = useState("")
    const [desciption,setDescription] = useState("")
    const [desc,setDesc] = useState("")
    const [status,setStatus] = useState("")
    const [issues,setIssues] = useState("")

    const [isStatusFreeSolo,setStatusFreeSolo] = useState(false)

    const [isShowMessage,setShowMessage] = useState(false);
    const [errorType,setErrorType] = useState("success");
    const [message,setMessage] = useState("");

    const [personName, setPersonName] = React.useState([]);

    const navigate = useNavigate();



    const columns = [
        {field: 'issue_id', headerName: 'Issue Id', width:100},
        {field: 'jira_ticket', headerName: 'Jira Ticket', width:150},
        {field: 'issue_name', headerName: 'Issue Name', width:150},
        {field: 'issue_type_name', headerName: 'Issue Type', width:150},
        {field: 'category_name', headerName: 'Category Name', width:150},
        {field: 'status', headerName: 'Status', width:150},
        {field: 'issue_description', headerName: 'Description',width:300},
        {field: 'opr', headerName: 'Operation', width:100,renderCell: (params) => {
                return (<div style={{width:"100%",textAlign:"center"}}><EditIcon /></div>);
        }},

    ];

    const rows = [
        { id: 1, jira: '#1821828', firstName: 'Jon', age: 14 },
        { id: 2, jira: '#1291929', firstName: 'Cersei', age: 31 },
        { id: 3, jira: '#929339', firstName: 'Jaime', age: 31 },

    ];

    useEffect(()=>{
        if(Constants.SIDE_MENU_OPEN){
            setWindowWidth(window.innerWidth-270)
        }else{
            setWindowWidth(window.innerWidth-30)
        }
    },[Constants.SIDE_MENU_OPEN]);

    let showSuccessErrorMessage = (type,msg)=>{
        setShowMessage(true);
        setMessage(msg);
        setErrorType(type);
    }

    useEffect(() => {
        try{
            document.getElementById("logs").style.display = "block";
            document.getElementById("users").style.display = "none";
            document.getElementById("admin").style.display = "none";
            document.getElementById("logout").style.display = "none";

            console.log(location.search)
            console.log(Constants.selectedLog)
            setData("")
            parseQuerySearch();
            if(location.search != ""){
                sessionStorage["QuckSightSearch"] = location.search;

                /*const searchParams = new URLSearchParams(location.search);
                let params = {};

                for (let param of searchParams) {
                    params[param[0]] = param[1];
                }
                console.log(params)
                Constants.PICKLIST = params.picklist
                Constants.CUST_ID = params.customer_id
                Constants.DC_ID = params.dc_id;
                Constants.INF_ID = params.inf_id;
                setPickList(params.picklist);
                setDC(params.dcname)
                setWrapper(params.pc)
                setTDate(params.trn_dt);
                setSysErr(params.sts);
                setExpWeight(params.exp_w);
                setActWeight(params.act_w);
                setDelta(params.delta);
                setThreshold(params.threshold);
                setExpCC(params.exp_cc);
                setActCC(params.act_cc);*/
            }
            if(Constants.selectedLog != Constants.DOWNLOAD_PICKLIST && Constants.selectedLog !=Constants.CREATE_ISSUE) {
                //getData();
                resetForm();
                setData("")
               // getHeaderData();
                getData()
            }else{
                console.log("download picklist")
            }

            /*if(Constants.selectedLog == Constants.DOWNLOAD_CMD){
                setCopy(true)
            }*/

            setCopy(false)
            if(Constants.selectedLog == Constants.CREATE_ISSUE){
                //resetForm();
                //setOpen(true);
            }else if(Constants.selectedLog == Constants.DOWNLOAD_CMD){
                setCopy(true)
            }



        }catch (ex){
            console.log(ex.message);
        }
    }, [Constants.selectedLog]);

    useEffect(()=>{
        /*getCategories();
        getIssueTypeList();
        getIssues();*/
        populateData()

    },[Constants.selectedLog])
    useEffect(()=>{
        if(Constants.SELECT_ISSUE.indexOf(Constants.CREATE_ISSUE)>=0){
            populateData();
            setOpen(true)
        }
    },[Constants.SELECT_ISSUE])

    let populateData = ()=>{
        resetForm();
        getCategories();
        getIssueTypeList();
        getIssues();
    }

    let parseQuerySearch = ()=>{
        let query = "";
        if(location.search){
            query = location.search;
        }else{
            query = sessionStorage["QuckSightSearch"];
        }
        if(query && query != "null"){
            const searchParams = new URLSearchParams(query);
            let params = {};

            for (let param of searchParams) {
                params[param[0]] = param[1];
            }
            //console.log(params)
            Constants.PICKLIST = params.picklist
            Constants.CUST_ID = params.customer_id
            Constants.DC_ID = params.dc_id;
            Constants.INF_ID = params.inf_id;
            sessionStorage["PICKLIST"] = params.picklist;
            sessionStorage["CUST_ID"] = params.customer_id;
            sessionStorage["DC_ID"] = params.dc_id;
            sessionStorage["INF_ID"] = params.inf_id;

            setPickList(params.picklist);
            setDC(params.dcname)
            setWrapper(params.pc)
            setTDate(params.trn_dt);
            setSysErr(params.sts);
            setExpWeight(params.exp_w);
            setActWeight(params.act_w);
            setDelta(params.delta);
            setThreshold(params.threshold);
            setExpCC(params.exp_cc);
            setActCC(params.act_cc);
        }else{
            if(sessionStorage["PICKLIST"] != "null"){
                Constants.PICKLIST = sessionStorage["PICKLIST"];
            }
            if(sessionStorage["CUST_ID"] != "null"){
                Constants.CUST_ID =  sessionStorage["CUST_ID"];
            }
            if(sessionStorage["DC_ID"] != "null"){
                Constants.DC_ID =  sessionStorage["DC_ID"];
            }
            if(sessionStorage["INF_ID"] != "null"){
                Constants.INF_ID =  sessionStorage["INF_ID"];
            }
        }

    }

    let onMessage = ()=>{

    }

    let handleUpdate = ()=>{

    }

    let getArray = (arr)=>{
        if(Array.isArray(arr)){
            return arr;
        }
        return [arr];
    }

    let cloneArray = (nodesArray)=>{
        let clonedArray = JSON.parse(JSON.stringify(nodesArray))
        return clonedArray;
    }


    let getIssues = async ()=>{
        setIssues([]);
        setProgress(true)
        let result = await getIssueList();
        console.log(result);
        if(result && result.Data) {
            let dataArray = [];
            dataArray = getArray(result.Data);
            setIssues(dataArray)
            issuesArray = dataArray;
            setProgress(false)
            //setIssueList(cloneArray(dataArray))
        }
    }
    let getIssueTypeList = async()=>{
        setProgress(true)
        let result = await getIssuTypes();
        console.log(result);
        setTypeList([])
        setIssueTypes([])
        if(result && result.Data){
            let dataArray = [];
            let tempArray = []
            tempArray = getArray(result.Data);
            tempArray.forEach((item,idx)=>{
                if(item.category_name){
                    dataArray.push(item.issue_type_name)
                }
            })
            setTypeList(cloneArray(tempArray))
            setIssueTypes(tempArray);
            setProgress(false)
        }
    }
    let getCategories = async ()=>{
        setProgress(true)
        let result = await getCategoryList();
        setCategories([]);
        setIssueCategories([]);
        //console.log(result);
        if(result && result.Data){
            let dataArray = [];
            let tempArray = []
            tempArray = getArray(result.Data);
            tempArray.forEach((item,idx)=>{
                if(item.category_name){
                    dataArray.push(item.category_name)
                }
            })
            categoryArray = tempArray;
           // setCategories(tempArray);
            //console.log(cloneArray(tempArray));
            //setIssueCategories(cloneArray(tempArray));
            setProgress(false)
        }
    }

    let filterCategories = ()=>{
        let dataArray = [];
        if(issueTypeId){
            categoryArray.map((item)=>{
                if(item.issue_type_id == issueTypeId){
                    dataArray.push(item);
                }
            });
        }
       // setCatValue("")
        setCategories(dataArray);
        setIssueCategories(cloneArray(dataArray));
    }


    let getData = async ()=>{

        try {
            setProgress(true);
           // setCreate(false);
            let fType = Constants.selectedLog;
            if(fType == STITCHING_VIEW){
                setFileType(HTML_FILE_TYPE);
            }else{
                setFileType(TEXT_FILE_TYPE);
            }
            if(fType == HEURISTIC || fType == RESPONSE || fType == ST_CORRECTIONS || fType == PICKLIST){
                downloadFileType = JSON_FILE_TYPE;
            }else if(fType == STITCHING_VIEW){
                downloadFileType = HTML_FILE_TYPE;
            }else{
                downloadFileType = LOG_FILE_TYPE;
            }

            logData = await getQuicksightDataDetails(Constants.PICKLIST,Constants.selectedLog,Constants.CUST_ID,Constants.DC_ID);
           // console.log(logData);
            if(Constants.PICKLIST){
                try {

                    fileName = tDate+"_"+Constants.selectedLog+""+downloadFileType;
                }catch (ex){
                    console.log(ex.message)
                }

            }
            if(logData && logData.data){
                // fileName = params.data_type;
                sessionStorage["QuckSightSearch"] = null;
                if(Constants.selectedLog == HEURISTIC || Constants.selectedLog == RESPONSE || Constants.selectedLog == ST_CORRECTIONS || Constants.selectedLog == PICKLIST){
                    //const obj = JSON.parse(logData.data.fc);

                    // console.log(JSON.stringify(obj, null, 2));
                    textData = JSON.stringify(logData.data,null,'\t');
                    setData(textData)
                }else{
                    textData = logData.data;
                    setData(textData)
                }

            }
            setProgress(false);
        }catch (ex){
            console.log(ex.message)
            setProgress(false);
        }
    }

    let onDownload = ()=>{
        console.log(textData)
        if(textData){
            setProgress(true);
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(textData));
            element.setAttribute('download', fileName);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            setProgress(false);
        }

    }

    let onCopy = ()=>{
        console.log(data)
        try{
            let copyData = data;
            if(data && data.indexOf("\"")>=0){
                let dataArray = data.split("\"");
                console.log(dataArray)
                copyData = dataArray[1];
            }
            navigator.clipboard.writeText(copyData);
        }catch (ex){}


    }

    let onSearchPicklist = ()=>{
        console.log(picklist)
        sessionStorage["PICKLIST"] = picklist;
        Constants.PICKLIST = picklist;
        resetForm();
        setData("")
        getHeaderData();
        getData()

    }


    let getHeaderData = async ()=>{
        try{
            let logData = await getQuicksightDataDetails(Constants.PICKLIST,"HEAD",Constants.CUST_ID,Constants.DC_ID);
            //console.log(logData);
            if(logData && logData.data && logData.data.main_header && logData.data.main_header != "NO PICKLIST FOUND"){
               // setPickList(params.picklist);
                setDC(logData.data.main_header[2])
                setWrapper(logData.data.main_header[3])
                setTDate(logData.data.main_header[4]);
                setSysErr(logData.data.main_header[5]);
                setExpWeight(logData.data.main_header[6]);
                setActWeight(logData.data.main_header[7]);
                setDelta(logData.data.main_header[11]);
                setThreshold(logData.data.main_header[10]);
                setExpCC(logData.data.main_header[8]);
                setActCC(logData.data.main_header[9]);

            }else{
                setDC("")
                setWrapper("")
                setTDate("");
                setSysErr("");
                setExpWeight("");
                setActWeight("");
                setDelta("");
                setThreshold("");
                setExpCC("");
                setActCC("");
                sessionStorage["PICKLIST"] = null;
            }
        }catch (ex){}
    }

    let uploadFile = ()=>{
       // document.getElementById('fileInput').click()
        const fileInput = document.getElementById("fileInput");
        if(fileInput){
            fileInput.click();
            fileInput.addEventListener("change", (event) => {
                browseFile = event.target.files[0]; // Get the first selected file

                if (browseFile) {
                    setAttachFileName(browseFile.name);


                    // Example: Display an alert with file name
                    //alert("Selected file: " + file.name);

                    // Perform further actions, like validating or uploading the file
                    // uploadFile(file);
                } else {
                    console.log("No file selected.");
                }
            });
        }

       // const file = fileInput.files[0];
        //console.log(file)
    }
    let onClickFile = (e)=>{
        console.log(e);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        populateData();
        setOpen(false);
        navigate("/quick-sight-data");
        Constants.selectedLog = Constants.HEURISTICS;
       // quick-sight-data
       // setIssueType("")
        //Constants.selectedLog
    };

    let handleReset = ()=>{
        resetForm();
    }
    let handleCreate = async ()=>{
        /*{
            "issue_type_id": 1,
            "category_id": 1,
            "issue_name": "Test Issue",
            "issue_description": "Test Description",
            "jira_ticket": "XX-1234",
            "status": "Open"
        }*/
        //console.log(issueTypeId,issueCategoryId)
        //console.log(issueName,issueType,catValue,desciption,jiraTicket,status)
        if((issueTypeId || issueType) && (issueCategoryId || catValue) && issueName && desciption ){
            let obj = {}

            if(issueTypeId){
                obj.issue_type_id = issueTypeId;
            }else{
                obj.issue_type_name = issueType;
            }
            if(issueCategoryId){
                obj.category_id = issueCategoryId;
            }else{
                obj.category_name = catValue;
            }

            obj.issue_name = issueName;
            obj.issue_description = desciption;
            obj.jira_ticket = jiraTicket;
            obj.status = status;
            setProgress(true)
            let result = null;
            if(operation == Constants.CREATE){
                result = await createIssue(obj);
            }else if(operation == Constants.UPDATE){
               // obj.issue_id = issueId;
                result = await updateIssue(obj,issueId);
            }

            console.log(result)
            if(result && result == "success"){
                if(operation == Constants.CREATE){
                    showSuccessErrorMessage("success","Issue Created Successfully");
                }else if(operation == Constants.UPDATE){
                    showSuccessErrorMessage("success","Issue Updated Successfully");
                }
            }else{
                showSuccessErrorMessage("error",result);
            }
            getIssues();
            getIssueTypeList();
            getCategories();
            resetForm();
            setProgress(false)


        }
    }

    let resetForm = ()=>{
        operation = Constants.CREATE;
        setStatusFreeSolo(false);
        setPersonName([]);
        objType = null;
        objCategory = null;
        issueTypeId = null;
        issueCategoryId = null;
        issueId = null;
        setIssueName("");
        setJiraTicket("");
        setDescription("")
        setStatus("")
        setCatValue("")
        setIssueType("")
        setDesc("")

    }
    let onClickAssociation = async ()=>{
        let issueIds =  getIssueIds();
        if(issueIds && personName.length>0 && issueIds.length>0){
            issueIds = issueIds.join();
            console.log(issueIds);
            let obj = {
                "customer_id": Constants.CUST_ID,
                "dc_id": Constants.DC_ID,
                "inference_id": Constants.INF_ID,
                "customer_picklist_id": Constants.PICKLIST,
                "issue_id": ""+issueIds,
                "category_id": issueCategoryId,
                "notes": desc,
                "status":"error"
            }

            if(issueCategoryId && issueTypeId){
                setProgress(true);
                let result = await issueAssociation(obj);
                if(result && result == "success") {
                    showSuccessErrorMessage("success", "Issue Associated Successfully");
                }
                console.log(result)
                setProgress(false);
            }
        }


    }

    const cataValue = ()=>{
        return categories.find((option) => option.category_name == catValue);
    }


    const onGridRowClick = (params,event, details) => {
        console.log(params,event,details)
        if(params && params.row){
            setStatusFreeSolo(true)
            operation = Constants.UPDATE;
            issueTypeId = params.row.issue_type_id;
            issueCategoryId = params.row.category_id;
            issueId = params.row.issue_id;
            setIssueName(params.row.issue_name);
            setIssueType(params.row.issue_type_name)
            setCatValue(params.row.category_name);
            setJiraTicket(params.row.jira_ticket)
            setDescription(params.row.issue_description);
            setStatus(params.row.status)
            filterCategories();

            setTimeout(()=>{
                setStatusFreeSolo(false)
            },1000)
        }
    };


    const handleChange = (event) => {
        const {target: { value },} = event;
        console.log(event)
        console.log(value)
        setPersonName(typeof value === 'string' ? value.split(',') : value);
    };

    let filterIssues = ()=>{
        setIssueList([]);
        setPersonName([])
        let dataArray = [];
        if(issueTypeId && issueCategoryId){
            issuesArray.map((item)=>{
                if(item.issue_type_id == issueTypeId && item.category_id == issueCategoryId){
                    dataArray.push(item);
                }
            });
        }
        setIssueList(dataArray)
    }

    let getIssueId = (issueName)=>{
        if(issueList && issueList.length>0){
            let found = issueList.find((element)=> {
                 if(element.issue_name == issueName){
                     return element.issue_id;
                 }
            });
            return found;
        }
        return null;
    }

    let getIssueIds = ()=>{
        let idArray = [];
        let data = personName;
        if(data && data.length>0){
            data.map((name)=>{
                let item = issueList.find((element)=> {
                    return element.issue_name == name
                });
                if(item){
                    idArray.push(item.issue_id);
                }
                console.log(item)
            })
        }
        return idArray;
    }


    return (
        <>
            <div style={{position:"absolute",top:"50%",left:"50%",zIndex:9999999999}}>
                {progress?<CircularProgress />:""}
            </div>
            <CustomSuccessMessage   message={message} type={errorType} flag={isShowMessage} onHide={()=>{setShowMessage(false)}}/>
            <Dialog  open={open} >
                <AppBar sx={{ position: 'relative' }} elevation={0}>
                    <Toolbar className={"toolbar-height"} variant="dense" elevation={0}>
                        <Typography sx={{ ml: 0, flex: 1,color:"#FFF" }} variant="h6" component="div">{operation == Constants.CREATE?"Create Issue":"Update Issue"}</Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose}>
                            <CloseIcon sx={{color:"#FFF"}} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{backgroundColor:"#f2eded33",padding:"10px"}}>
                    <div style={{border:"1px solid #ede7e7",backgroundColor:"#FFF",padding:"10px",marginBottom:"5px",display:(isCreate?"":"none")}}>
                        <Grid container spacing={2} sx={{margin:"0px",marginBottom:"10px"}}>
                            <Grid container xs={12} lg={12} xl={12} gap={"0px"}>
                                <Grid container xs={3} lg={3} xl={3} sx={{paddingRight:"5px"}}>
                                    <TextField label="Issue Name" sx={{fontWeight:"bold"}} error={!issueName}  InputLabelProps={{ shrink: true }} size={"small"} fullWidth={true}
                                    value={issueName} onChange={(e)=>{setIssueName(e.target.value)}}/>
                                </Grid>
                                <Grid container xs={3} lg={3} xl={3} className={"padding-right-class"}>

                                    <Grid xs={12} lg={12} xl={12}>
                                        <Autocomplete  popupIcon={<ArrowDownwardIcon />} options={issueTypes} getOptionLabel={(option) => option.issue_type_name} freeSolo={true} inputValue={issueType} onInputChange={(event,newValue)=>{
                                           // console.log(newValue)
                                            setIssueType(newValue)
                                        }} onChange={(event,newValue)=>{
                                           // console.log(newValue)
                                            objType = newValue;
                                            issueTypeId = "";
                                            if(objType){
                                                issueTypeId = newValue.issue_type_id;
                                            }
                                            setTimeout(()=>{
                                                setCatValue("")
                                                issueCategoryId = ""
                                            })
                                            filterCategories();

                                        }} onBlurCapture={(event,newValue)=>{
                                            if(event.target && event.target.value) {
                                                let item = issueTypes.find((element)=> {
                                                    return element.issue_type_name == event.target.value
                                                });
                                                if(!item) {
                                                    objType = null;
                                                    issueTypeId = "";
                                                    //setIssueType("");
                                                }else{
                                                    objType = item;
                                                    issueTypeId = item.issue_type_id;
                                                }
                                            }
                                            setTimeout(()=>{
                                                if(issueTypeId){
                                                    setCatValue("")
                                                    issueCategoryId = ""
                                                }

                                            })
                                            filterCategories();

                                        }} renderInput={(params) => <TextField {...params} size="small" error={!issueType} label={"Issue Type"} InputLabelProps={{ shrink: true }} />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container xs={3} lg={3} xl={3} className={"padding-right-class"}>
                                    <Grid xs={12} lg={12} xl={12}>
                                        <Autocomplete   options={categories}  getOptionLabel={(option) => option.category_name}  inputValue={catValue}  freeSolo={true} onInputChange={(event,newValue)=>{
                                          //  console.log(event)
                                            setCatValue(newValue)
                                        }} onChange={(event,newValue)=>{
                                           // console.log(newValue)
                                            objCategory = newValue;
                                            issueCategoryId = "";

                                            if(objCategory){
                                                issueCategoryId = newValue.category_id;
                                            }else {
                                               // setCatValue("");
                                            }
                                        }} onBlurCapture={(event,newValue)=>{
                                            if(event.target && event.target.value) {
                                                let item = categories.find((element)=> {
                                                    return element.category_name == event.target.value
                                                });
                                                if(!item) {
                                                    objCategory = null;
                                                    issueCategoryId = "";
                                                    //setCatValue("");
                                                }else{
                                                    objCategory = item;
                                                    issueCategoryId = item.category_id;
                                                    setCatValue(item.category_name);
                                                }
                                            }

                                        }} renderInput={(params) => <TextField {...params} size="small" label={"Issue Category"} error={!catValue} InputLabelProps={{ shrink: true }} />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container xs={3} lg={3} xl={3} sx={{paddingRight:"5px"}}>
                                    <Grid xs={11} lg={11} xl={11}>
                                        <TextField label="Jira Ticket"  InputLabelProps={{ shrink: true }} size={"small"} fullWidth={true}
                                        value={jiraTicket} onChange={(e)=>{setJiraTicket(e.target.value)}}/>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{margin:"0px"}}>
                            <Grid container xs={6} lg={6} xl={6} className={"padding-right-class"}>

                                <Grid xs={12} lg={12} xl={12}>
                                    <Autocomplete  options={descriptions}  freeSolo={true} inputValue={desciption} onInputChange={(event,newValue)=>{
                                        console.log(newValue)
                                        setDescription(newValue);
                                    }} renderInput={(params) => <TextField {...params} error={!desciption} size="small" label={"Description"} InputLabelProps={{ shrink: true }} />}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={3} lg={3} xl={3} className={"padding-right-class"}>

                                <Grid xs={12} lg={12} xl={12}>
                                    <Autocomplete  options={statuses}  freeSolo={true}  value={status}  onInputChange={(event,newValue)=>{
                                       //console.log(newValue)
                                        setStatus(newValue)
                                    }} onChange={(event,newValue)=>{
                                         console.log(newValue)
                                    }} onBlurCapture={(event,newValue)=>{
                                        if(event.target && event.target.value) {
                                            if(statuses.indexOf(event.target.value) == -1) {
                                                setStatus("");
                                            }
                                        }

                                    }} renderInput={(params) => <TextField {...params} onBlurCapture={(e)=>{
                                       // console.log(e)
                                    }}  size="small" label={"Status"} InputLabelProps={{ shrink: true }} />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={3} lg={3} xl={3} style={{display:"none"}} >

                                <Grid container xs={12} lg={12} xl={12}>
                                    <Box sx={{width:"85%",height:"40px" }}>
                                        <input type="file" id="fileInput" style={{display:"none"}} />
                                        <TextField label="Attachment" disabled={true} value={attchFileName} InputLabelProps={{ shrink: true }} size={"small"} fullWidth={true}/>
                                    </Box>
                                    &nbsp;
                                    <BrowserUpdatedIcon sx={{cursor:"pointer"}} onClick={uploadFile}/>

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                <Box sx={{ height:"80%", width: '100%' }}>
                    <CustomPaper height="100%" padding="10px">
                        <div style={{height:"450px"}}>
                                <DataGrid rows={issues} columns={columns} columnHeaderHeight={45}  rowHeight={35} hideFooter={false}
                                          slotProps={{toolbar: {showQuickFilter:true}}} getRowId={(row) => row.issue_id}
                                           onRowClick={onGridRowClick}
                                          sx={{display:"flex",borderColor: "#FFF !important"}} slots={{
                                    toolbar: GridToolbar,
                                    noResultsOverlay: () => (
                                        <Box
                                            sx={{
                                                display: "flex", height: "100%", justifyContent: "center",
                                                alignItems: "center", flexDirection: "column",
                                            }}>
                                            <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                                                No Results Found
                                            </Typography>

                                        </Box>
                                    ),
                                    noRowsOverlay:() => (
                                        <Box
                                            sx={{
                                                display: "flex", height: "100%", justifyContent: "center",
                                                alignItems: "center", flexDirection: "column",
                                            }}>
                                            <Typography style={{fontWeight:"400", fontSize:"16px"}}>
                                                No Rows
                                            </Typography>
                                        </Box>
                                    ),
                                }}/>
                        </div>
                    </CustomPaper>
                </Box>

            </DialogContent>
                <DialogActions style={{height:"45px",backgroundColor:"#f0f8ff"}}>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <Button variant="contained" onClick={handleReset}>Reset</Button>
                    <Button variant="contained" onClick={handleCreate}>{operation == Constants.CREATE?"Create":"Update"}</Button>
                </DialogActions>
            </Dialog>

            <div style={{ width: windowWidth }}>
                <div style={{position:"absolute",top:"50%",left:"50%"}}>
                    {progress?<CircularProgress />:""}
                </div>
                <div style={{border:"1px solid #ede7e7",marginBottom:"5px",paddingLeft:"5px"}}>
                    <Grid container spacing={2} sx={{margin:"0px"}}>
                        <Grid xs={8} lg={8} xl={8}>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Distribution Center</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Wrapper Name</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Pick List Id</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontSize"}>Transaction Date</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontBold"}>{dc}</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontBold"}>{wrapper}</Typography>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Box sx={{ p: '2px 4px',marginTop:"5px",marginBottom:"5px", display: 'flex',
                                        width:"90%",height:"40px",border:"1px solid #f2eded", alignItems: 'center' }}>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="picklist" value={picklist} onChange={(e)=>{
                                            console.log(e.target.value)
                                            setPickList(e.target.value)
                                        }}

                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchPicklist}>
                                            <SearchIcon />
                                        </IconButton>

                                    </Box>
                                </Grid>
                                <Grid xs={3} lg={3} xl={3}>
                                    <Typography class={"qFontBold"}>{tDate}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={2} lg={2} xl={2}>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={12} lg={12} xl={12}>
                                    <Typography class={"qFontSize"}>Status</Typography>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid xs={12} lg={12} xl={12}>
                                    <Typography class={"qFontBold"}>{sysErr}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid xs={2} lg={2} xl={2} >
                            <IconButton color="primary" title={"Download"} onClick={onDownload}>
                                <DownloadIcon />
                            </IconButton>
                            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                            <IconButton color="primary" title={"copy"} onClick={onCopy} sx={{display:isCopy?"":"none"}}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{margin:"0px",marginTop:"10px",marginBottom:"10px"}}>
                        <Grid xs={12} lg={12} xl={12}>
                            <Grid container spacing={2} sx={{margin:"0px"}}>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Expected Weight : &nbsp;</Typography>
                                    <Typography class={"qFontBold"}>{expWeight}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Actual Weight : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {actWeight}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Delta : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {delta}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Threshold : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {threshold}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Expected Cases : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {expCC}</Typography>
                                </Grid>
                                <Grid container xs={2} lg={2} xl={2}>
                                    <Typography class={"qFontSize"}>Actual Cases : &nbsp;</Typography>
                                    <Typography class={"qFontBold"} > {actCC}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={{border:"1px solid #ede7e7",padding:"5px",marginBottom:"5px",display:(isCreate?"":"none")}}>
                    <div style={{textAlign:"right"}}>
                        <IconButton color="primary" sx={{padding:"0px"}} title={minimize?"Maximize":"Minimize"} onClick={()=>{
                            setMinimize(!minimize)
                        }}>
                            {minimize?<HomeMaxIcon />:<HomeMiniIcon />}
                        </IconButton>
                    </div>
                    <div style={{marginTop:"-20px",display:minimize?"none":""}}>
                        <Grid container spacing={2} sx={{margin:"0px",marginBottom:"10px"}}>
                            <Grid container xs={12} lg={12} xl={12}>
                                <Grid container xs={4} lg={4} xl={4} className={"padding-right-class"}>
                                    <Grid xs={12} lg={12} xl={12}>
                                        <Autocomplete  options={typeList} inputValue={issueType} getOptionLabel={(option) => option.issue_type_name} freeSolo={true} onInputChange={(event,newValue)=>{
                                            console.log(newValue)
                                            setTimeout(()=>{
                                                setCatValue("");
                                                issueCategoryId = "";
                                                },100)
                                            setIssueType(newValue);
                                        }} onChange={(event,newValue)=>{
                                            console.log(newValue)
                                            objType = newValue;
                                            issueTypeId = "";
                                            if(objType){
                                                issueTypeId = newValue.issue_type_id;
                                                setIssueType(objType.issue_type_name);
                                            }else {

                                            }
                                            setTimeout(()=>{
                                                if(issueTypeId){
                                                    setCatValue("");
                                                    issueCategoryId = "";
                                                }

                                            },100)
                                            filterIssues();
                                            filterCategories();

                                        }} onBlurCapture={(event,newValue)=>{
                                            if(event.target && event.target.value) {
                                                let item = typeList.find((element)=> {
                                                    return element.issue_type_name == event.target.value
                                                });
                                                if(!item) {
                                                    objType = null;
                                                    issueTypeId = "";
                                                    setIssueType("");
                                                    setCatValue("")
                                                }else{
                                                    objType = item;
                                                    issueTypeId = item.issue_type_id;
                                                    setIssueType(item.issue_type_name);
                                                    setCatValue("")
                                                }
                                            }
                                            setTimeout(()=>{
                                                if(issueTypeId){
                                                    setCatValue("");
                                                    issueCategoryId = "";
                                                }

                                            },100)
                                            filterIssues();
                                            filterCategories();
                                        }} renderInput={(params) => <TextField {...params} error={!issueTypeId} size="small" label="Issue Type"  InputLabelProps={{ shrink: true }}  fullWidth={true}/>}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container xs={4} lg={4} xl={4} className={"padding-right-class"}>

                                    <Grid xs={12} lg={12} xl={12}>
                                        <Autocomplete  options={issueCategories} inputValue={catValue} getOptionLabel={(option) => option.category_name}  freeSolo={true} onInputChange={(event,newValue)=>{
                                            console.log(newValue)
                                            setCatValue(newValue);
                                        }} onChange={(event,newValue)=>{
                                            console.log(newValue)
                                            objCategory = newValue
                                            issueCategoryId = "";
                                            if(objCategory){
                                                issueCategoryId = newValue.category_id;
                                               // setIssueType(newValue.issue_type_name);
                                                 setCatValue(objCategory.category_name);
                                            }
                                            filterIssues();
                                        }} onBlurCapture={(event,newValue)=>{
                                            if(event.target && event.target.value) {
                                                let item = issueCategories.find((element)=> {
                                                    return element.category_name == event.target.value
                                                });
                                                if(!item) {
                                                    objCategory = null;
                                                    issueCategoryId = "";
                                                    setCatValue("");
                                                }else{
                                                    objCategory = item;
                                                    issueCategoryId = item.category_id;
                                                    setCatValue(item.category_name);
                                                }
                                            }

                                        }} renderInput={(params) => <TextField {...params} error={!issueCategoryId} size="small" label="Issue Category"  InputLabelProps={{ shrink: true }}  fullWidth={true} />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container xs={4} lg={4} xl={4} className={"padding-right-class"}>

                                    <Grid xs={11} lg={11} xl={11}>
                                        <FormControl variant="outlined" margin={"1"} style={{ width: "100%"}}>
                                        <InputLabel id="test-select-label" shrink={true}>Issue</InputLabel>
                                        <Select sx={{width:"100%",height:"40px"}}
                                            label={"Issue"} labelId="test-select-label" variant="outlined"
                                            multiple value={personName} onChange={handleChange} displayEmpty notched={true}
                                                renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps} InputLabelProps={{ shrink: true }} error={personName.length == 0?true:false}
                                        >
                                            {issueList.map((item) => (
                                                <MenuItem key={item.issue_id} value={item.issue_name}>
                                                    <Checkbox checked={personName.includes(item.issue_name)} />
                                                    <ListItemText primary={item.issue_name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        </FormControl>
                                        {/*<Autocomplete  options={issueList}  getOptionLabel={(option) => option.issue_name} freeSolo={false} onInputChange={(event,newValue)=>{
                                            console.log(newValue)
                                        }} onChange={(event,newValue)=>{
                                            console.log(newValue)
                                            //objCategory = newValue
                                            issueId = ""
                                            if(newValue){
                                                issueId = newValue.issue_id;
                                            }

                                        }} renderInput={(params) => <TextField {...params} error={!issueId} size="small" label="Issue"  InputLabelProps={{ shrink: true }}  fullWidth={true}  />}
                                        />*/}
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{margin:"0px"}}>

                            <Grid container xs={6} lg={6} xl={6}>

                                <Grid xs={12} lg={12} xl={12}>
                                    <TextField label="Notes" multiline={true} maxRows={20} value={desc}  InputLabelProps={{ shrink: true }}
                                               onChange={(e)=>{setDesc(e.target.value)}} fullWidth={true}/>
                                </Grid>
                            </Grid>


                            <Grid container xs={2} lg={2} xl={2}>
                                &nbsp;
                                <Button variant="contained" sx={{width:"60%",height:"45px"}} onClick={onClickAssociation}>Assign</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>


                {fileType == TEXT_FILE_TYPE?<textarea style={{width:"100%",fontSize:"14px",border:"1px solid #dfd9d9",height:((window.innerHeight-400))}} value={data} readOnly />:""}
                {fileType == HTML_FILE_TYPE? <div dangerouslySetInnerHTML={{__html: data}}></div>:""}
                {fileType == HEURISTIC?<textarea style={{width:"100%",fontSize:"14px",height:((window.innerHeight-400))}} value={data} readOnly />:""}


            </div>


        </>
    )
}



export default (QuickSightDataDetails);
